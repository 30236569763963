import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'

import { Element } from '../../store/element'
import { useSelector } from '../../store'
import { BaseSidebar } from '../../components/BaseSidebar'
import { useSelectedElements } from '../../store/app'
import { Input } from './components/Input'
import { Stack } from '../../components/Stack'
import { Section } from './components/Section'
import { Divider } from './components/Divider'

const Container = styled(BaseSidebar)`
  border-left-width: 1px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 8px;
  }
`

const Label = styled.label`
  width: 40px;
  font-size: 12px;
`

interface InputProps {
  label: string
  value: number
  onChange: (value: number) => void
}

const InlineInput = ({ label, value, onChange }: InputProps) => {
  return (
    <Row style={{ width: '50%' }}>
      <Label htmlFor={label}>{label}</Label>
      <Input
        id={label}
        type="number"
        onChange={(value) => onChange(Number(value))}
        value={value.toString()}
      />
    </Row>
  )
}

export const RightSidebar = () => {
  const selectedElements = useSelectedElements()
  
  const selectedElement = useSelector(state => {
    if (selectedElements.ids.length !== 1) {
      return undefined
    }

    return state.element.elements.find(el => el.id === selectedElements.ids[0])
  })
  const dispatch = useDispatch()

  if (selectedElement === undefined) {
    return (
      <Container>None</Container>
    )
  }

  const updateProperty = (property: keyof Element, value: number) => dispatch({
    type: 'ELEMENT_UPDATE_PROPERTY',
    id: selectedElement.id,
    property,
    value,
  })

  return (
    <Container>
      <Stack direction="column">
        <Section title="Position">
          <Stack>
            <InlineInput
              label="x"
              onChange={(value) => updateProperty('x', value)}
              value={selectedElement.x}
            />
            <InlineInput
              label="y"
              onChange={(value) => updateProperty('y', value)}
              value={selectedElement.y}
            />
          </Stack>
        </Section>
        {selectedElement.type === 'rectangle' && (
          <>
            <Divider />
            <Section title="Dimension">
              <Stack direction="column" spacing="spacing-3">
                <InlineInput
                  label="Width"
                  // @ts-ignore
                  onChange={(value) => updateProperty('width', value)}
                  value={selectedElement.width}
                />
                <InlineInput
                  label="Height"
                  // @ts-ignore
                  onChange={(value) => updateProperty('height', value)}
                  value={selectedElement.height}
                />
              </Stack>
            </Section>
          </>
        )}
      </Stack>
    </Container>
  )
}