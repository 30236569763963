import React from 'react'
import styled from '@emotion/styled'

import { Canvas } from './Canvas'
import { LeftSidebar } from './LeftSidebar'
import { useGlobalShortcut } from '../useGlobalShortcut'
import { RightSidebar } from './RightSidebar'
import { Header } from './Header'

const AppContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

export const App = () => {
  useGlobalShortcut()

  return (
    <AppContainer>
      <Header />
      <Main>
        <LeftSidebar />
        <Canvas />
        <RightSidebar />
      </Main>
    </AppContainer>
  )
}
