import React, { useEffect, useState, useRef } from 'react'
import { useCanvasRef, usePointerPositionRef } from '../Canvas'
import { useDispatch, useSelector } from '../../../store'
import { Rectangle } from './Rectangle'
import { Text } from './Text'

type RectangleDraft = {
  status: 'IN_PROGRESS'
  name: string
  x: number
  y: number
} | {
  status: 'READY_TO_COMMIT'
  name: string
  x: number
  y: number
  width: number
  height: number
}

interface DraftElementProps {
  type: 'rectangle' | 'text'
  position: {
    x: number
    y: number
  }
  onFinish: VoidFunction
}

type DraftProps = Pick<DraftElementProps, 'position' | 'onFinish'>

const DraftRectangle = ({ position, onFinish }: DraftProps) => {
  const pointerPositionRef = usePointerPositionRef()
  const canvasRef = useCanvasRef()
  const dispatch = useDispatch()

  const initialValue: RectangleDraft = {
    status: 'IN_PROGRESS',
    name: 'Rectangle',
    x: position.x,
    y: position.y,
  }
  const [draftData, setDraftData] = useState<RectangleDraft>(initialValue)

  useEffect(() => {
    const container = canvasRef.current

    const handlePointerMove = () => {
      setDraftData({
        ...draftData,
        status: 'READY_TO_COMMIT',
        width: pointerPositionRef.current.x - draftData.x,
        height: pointerPositionRef.current.y - draftData.y,
      })
    }

    const handlePointerUp = () => {
      if(draftData.status === 'READY_TO_COMMIT') {
        const { status, ...data } = draftData

        dispatch({
          type: 'DRAFT_ELEMENT_COMMIT',
          properties: {
            ...data,
            type: 'rectangle',
          }
        })
      }

      dispatch({
        type: 'UPDATE_MODE',
        mode: 'DEFAULT'
      })
      onFinish()
    }

    container.addEventListener('pointermove', handlePointerMove)
    container.addEventListener('pointerup', handlePointerUp)
    return () => {
      container.removeEventListener('pointermove', handlePointerMove)
      container.removeEventListener('pointerup', handlePointerUp)
    }
  })

  if (draftData.status === 'READY_TO_COMMIT') {
    return (
      <Rectangle
        id="draft"
        style={{
          top: draftData.y,
          left: draftData.x,
          width: draftData.width,
          height: draftData.height,
        }}
      />
    )
  }

  return null
}

type TextDraft = {
  content: string
  x: number
  y: number
}

const setCaretToTheEnd = (element: HTMLDivElement, content: string) => {
  const end = content.length

  const range = document.createRange()
  range.selectNodeContents(element)
  range.setStart(element.firstChild!, end)
  range.setEnd(element.firstChild!, end)

  const selection = window.getSelection()!
  selection.removeAllRanges()
  selection.addRange(range)
}

const DraftText = ({ position, onFinish }: DraftProps) => {
  const mode = useSelector(state => state.app.mode)
  const textRef = useRef<HTMLDivElement>(null!)
  const dispatch = useDispatch()
  const initialValue: TextDraft = {
    content: '',
    x: position.x,
    y: position.y,
  }
  const draftDataRef = useRef<TextDraft>(initialValue)

  useEffect(() => {
    textRef.current.focus()
  }, [textRef])

  useEffect(() => {
    if (mode !== 'CREATE_TEXT') {
      textRef.current.blur()
    }
  }, [mode])

  return (
    <Text
      ref={textRef}
      id="draft"
      style={{
        top: draftDataRef.current.y,
        left: draftDataRef.current.x,
      }}
      contentEditable
      suppressContentEditableWarning
      onInput={(evt) => {
        const newText = evt.currentTarget.innerText
        draftDataRef.current.content = newText
        textRef.current.innerText = newText

        setCaretToTheEnd(textRef.current, newText)
      }}
      onBlur={() => {
        if (draftDataRef.current.content.trim() !== '') {
          dispatch({
            type: 'DRAFT_ELEMENT_COMMIT',
            properties: {
              ...draftDataRef.current,
              name: draftDataRef.current.content,
              type: 'text',
            }
          })
        }

        dispatch({
          type: 'UPDATE_MODE',
          mode: 'DEFAULT'
        })

        onFinish()
      }}
    >
      {draftDataRef.current.content}
    </Text>
  )
}


export const DraftElement = ({ type, position, onFinish }: DraftElementProps) => {
  if (type === 'rectangle') {
    return <DraftRectangle position={position} onFinish={onFinish} />
  } else if (type === 'text') {
    return <DraftText position={position} onFinish={onFinish} />
  }

  return null
}
