import React from 'react'
import styled from '@emotion/styled'

import { useSelector } from '../../store'

import { Rectangle } from './components/Rectangle'
import { useDraftElement } from './useDraftElement'
import { useCanvasRef } from './Canvas'
import { Text } from './components/Text'

const Frame = styled.div`
  position: absolute;
  background-color: white;
`

export interface BoardPosition {
  zoom: number
  x: number
  y: number
}

export const Elements = () => {
  const canvasRef = useCanvasRef()
  const draftElement = useDraftElement(canvasRef)
  const elements = useSelector(state => state.element.elements)

  return (
    <>
      <Frame
        style={{
          top: 100,
          left: 100,
          width: 600,
          height: 1000,
        }}
      />
      {elements.map(el => {
        if (el.type === 'rectangle') {
          return (
            <Rectangle
              key={el.id}
              id={el.id}
              style={{
                left: el.x,
                top: el.y,
                width: el.width,
                height: el.height,
              }}
            />
          )
        }

        if (el.type === 'text') {
          return (
            <Text
              key={el.id}
              id={el.id}
              style={{
                left: el.x,
                top: el.y,
              }}
            >
              {el.content}
            </Text>
          )
        }

        return null
      })}
      {draftElement}
    </>
  )
}
