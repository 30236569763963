import styled from '@emotion/styled'

import { color } from '../styles'

export const BaseSidebar = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${color.layoutBackground};
  border-color: ${color.layoutBorder};
  border-style: solid;

  flex-basis: 300px;

  color: ${color.white};
`