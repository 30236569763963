import React from 'react'
import styled from '@emotion/styled'

import web_asset from './web_asset.svg'
import { useSelector, useDispatch } from '../../store'
import { BaseSidebar } from '../../components/BaseSidebar'
import { useSelectedElements } from '../../store/app'

const Container = styled(BaseSidebar)`
  border-right-width: 1px;
`

const ElementItem = styled.div<{ isHovered: boolean, isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  font-size: 13px;

  ${({ isHovered, isSelected }) => {
    let backgroundColor = ''
    let boxShadow = ''
    if (isSelected) {
      backgroundColor = 'background-color: rgb(192, 223, 250, 0.2);'
    }
    if (isHovered) {
      boxShadow = 'box-shadow: inset 0 0 0 2px #2887d8;'
    }

    return `${backgroundColor}${boxShadow}`
  }}
`

const Icon = styled.img`
  margin-right: 4px;
  width: 20px;
  height: 20px;
`

export const LeftSidebar = () => {
  const elements = useSelector(state => state.element.elements)
  const selectedElements = useSelectedElements()
  const dispatch = useDispatch()
  const hoveredNodeId = useSelector(state => state.app.hoveredElementId)

  return (
    <Container onClick={() => selectedElements.reset()}>
      {elements.map(element => (
        <ElementItem
          key={element.id}
          isHovered={hoveredNodeId === element.id}
          isSelected={selectedElements.isSelected(element.id)}
          onClick={(evt) => {
            evt.stopPropagation()
            selectedElements.select(element.id)
          }}
          onMouseOver={() => dispatch({ type: 'UPDATE_HOVERED_ELEMENT', id: element.id })}
          onMouseOut={() => dispatch({ type: 'UPDATE_HOVERED_ELEMENT', id: null })}
        >
          <Icon src={web_asset} alt="" />
          {element.name}
        </ElementItem>
      ))}
    </Container>
  )
}