import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

import { Stack } from '../../../components/Stack'

const Container = styled.div`
  padding: 8px 16px 16px;
  width: 100%;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
`

interface SectionProps {
  title: string
  children?: ReactNode
}

export function Section({ title, children }: SectionProps) {
  return (
    <Container>
      <Stack direction="column" spacing="spacing-3">
        <Title>{title}</Title>
        {children}
      </Stack>
    </Container>
  )
}