import React, { useState, useEffect, RefObject } from 'react'

import { useSelector } from '../../store'

import { DraftElement } from './components/DraftElement'
import { usePointerPositionRef } from './Canvas'

type DraftElement = {
  type: 'rectangle' | 'text'
  x: number
  y: number
} | null

const typeMapping = {
  CREATE_RECTANGLE: 'rectangle',
  CREATE_TEXT: 'text',
} as const

export const useDraftElement = (containerRef: RefObject<HTMLElement>) => {
  const mode = useSelector(state => state.app.mode)
  const [draftData, setDraftData] = useState<DraftElement>(null)
  const pointerPositionRef = usePointerPositionRef()

  useEffect(() => {
    const container = containerRef.current

    if (container === null || mode === 'DEFAULT') {
      return
    }

    const handlePointerDown = () => {
      const type = typeMapping[mode]
      setDraftData({
        type,
        x: pointerPositionRef.current.x,
        y: pointerPositionRef.current.y,
      })
    }

    container.addEventListener('pointerdown', handlePointerDown)
    return () => container.removeEventListener('pointerdown', handlePointerDown)
  })

  if (draftData !== null) {
    return (
      <DraftElement
        type={draftData.type}
        position={{
          x: draftData.x,
          y: draftData.y,
        }}
        onFinish={() => {
          setDraftData(null)
        }}
      />
    )
  }

  
  return null
}