import { useEffect, useRef, MutableRefObject } from 'react'
import { BoardPosition } from './Elements'

const ZOOM_MINIMUM_VALUE = 0.1

export const useGesture = (containerRef: MutableRefObject<HTMLDivElement>, onMoveRequest: (pos: BoardPosition) => void) => {
  const initialValues = useRef<{
    zoom: number,
    x?: number,
    y?: number,
  }>({
    zoom: 0,
    x: undefined,
    y: undefined,
  })
  const posValues = useRef({
    zoom: 1,
    x: 0,
    y: 0,
  })

  useEffect(() => {
    if (containerRef.current === null) {
      return
    }

    const container = containerRef.current

    const updateZoom = (value: number) => {
      if (value <= ZOOM_MINIMUM_VALUE) {
        return
      }

      // 50,3% 
      posValues.current.zoom = Number(value.toFixed(3))
    }

    onMoveRequest(posValues.current)

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault()

      if (event.ctrlKey) {
        const nextZoomValue = posValues.current.zoom - event.deltaY * 0.01
        
        updateZoom(nextZoomValue)
      } else {
        posValues.current.x -= event.deltaX * 2
        posValues.current.y -= event.deltaY * 2
      }

      onMoveRequest(posValues.current)
    }

    const handleGestureStart = (event: Event) => {
      event.preventDefault()
      // @ts-ignore
      initialValues.current.x = event.pageX - posValues.current.x
      // @ts-ignore
      initialValues.current.y = event.pageY - posValues.current.y
      initialValues.current.zoom = posValues.current.zoom
    }

    const handleGestureMove = (event: Event) => {
      event.preventDefault()
      
      // @ts-ignore
      updateZoom(initialValues.current.zoom * event.scale)

      // @ts-ignore
      posValues.current.x = event.pageX - initialValues.current.x
      // @ts-ignore
      posValues.current.y = event.pageY - initialValues.current.y
    
      onMoveRequest(posValues.current)
    }

    const handleGestureEnd = (event: Event) => {
      event.preventDefault()
    }

    container.addEventListener('wheel', handleWheel)
    container.addEventListener('gesturestart', handleGestureStart)
    container.addEventListener('gesturechange', handleGestureMove)
    container.addEventListener('gestureend', handleGestureEnd)
    return () => {
      container.removeEventListener('wheel', handleWheel)
      container.removeEventListener('gesturestart', handleGestureStart)
      container.removeEventListener('gesturechange', handleGestureMove)
      container.removeEventListener('gestureend', handleGestureEnd)
    }
  }, [containerRef, onMoveRequest])

  return containerRef
}