import React, { ComponentProps, ReactNode, forwardRef, Ref } from 'react'
import styled from '@emotion/styled'

import { BaseElement } from './BaseElement'
import { useSelector, useDispatch } from '../../../store'
import { useSelectedElements } from '../../../store/app'

const TextElement = styled(BaseElement)`
  position: absolute;
  padding: 8px;
  user-select: none;
`


interface TextProps extends Pick<ComponentProps<typeof TextElement>, 'onInput' | 'onBlur' | 'style' | 'contentEditable' | 'suppressContentEditableWarning'> {
  id: number | string
  children: ReactNode
}

export const Text = forwardRef(({ id, ...rest }: TextProps, ref: Ref<HTMLDivElement>) => {
  const selectedElements = useSelectedElements()
  const dispatch = useDispatch()
  const hoveredNodeId = useSelector(state => state.app.hoveredElementId)

  return (
    <TextElement
      ref={ref}
      isHovered={hoveredNodeId === id}
      onMouseOver={() => dispatch({ type: 'UPDATE_HOVERED_ELEMENT', id: id })}
      onMouseOut={() => dispatch({ type: 'UPDATE_HOVERED_ELEMENT', id: null })}
      onClick={(evt) => {
        evt.stopPropagation()
        if (typeof id === 'number') {
          selectedElements.select(id)
        }
      }}
      isSelected={typeof id === 'number' ? selectedElements.isSelected(id) : false}
      {...rest}
    />
  )
})
