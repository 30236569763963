import React, { ComponentProps } from 'react'
import styled from '@emotion/styled'

import { BaseElement } from './BaseElement'
import { useSelector, useDispatch } from '../../../store'
import { useSelectedElements } from '../../../store/app'

const RectangleElement = styled(BaseElement)`
  background-color: grey;
  position: absolute;
`


interface RectangleProps extends Pick<ComponentProps<typeof RectangleElement>, 'style'> {
  id: number | string
}

export const Rectangle = ({ id, ...rest }: RectangleProps) => {
  const selectedElements = useSelectedElements()
  const dispatch = useDispatch()
  const hoveredNodeId = useSelector(state => state.app.hoveredElementId)

  return (
    <RectangleElement
      isHovered={hoveredNodeId === id}
      onMouseOver={() => dispatch({ type: 'UPDATE_HOVERED_ELEMENT', id: id })}
      onMouseOut={() => dispatch({ type: 'UPDATE_HOVERED_ELEMENT', id: null })}
      onClick={(evt) => {
        evt.stopPropagation()
        if (typeof id === 'number') {
          selectedElements.select(id)
        }
      }}
      isSelected={typeof id === 'number' ? selectedElements.isSelected(id) : false}
      {...rest}
    />
  )
}