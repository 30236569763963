import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

type Spacing = 'spacing-1' | 'spacing-2' | 'spacing-3' | 'spacing-4'

const spacingMapping: Record<Spacing, string> = {
  'spacing-1': '2px',
  'spacing-2': '4px',
  'spacing-3': '8px',
  'spacing-4': '16px',
} 

const StyledDiv = styled.div<Pick<StackProps, 'spacing' | 'direction'>>`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};

  > *:not(:last-child) {
    ${({ direction, spacing }) => {
      const marginToApply = direction === 'column' ? 'margin-bottom' : 'margin-right'
      const spacingToApply = spacing ? spacingMapping[spacing] : '0'

      return `${marginToApply}: ${spacingToApply};`
    }};
  }
`

interface StackProps {
  spacing?: Spacing
  direction?: 'row' | 'column'
  children?: ReactNode
}

export function Stack(props: StackProps) {
  return <StyledDiv {...props} />
}