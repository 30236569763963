import React, { FC } from 'react'
import {
  Provider,
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import { elementReducer, ElementState } from './element'
import { appReducer, AppState } from './app'
import { AllActions } from './actions'

type RootState = {
  element: ElementState
  app: AppState
}

const store = configureStore<RootState, AllActions>({
  reducer: {
    element: elementReducer,
    app: appReducer,
  },
})

export const StoreProvider: FC = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}



type DispatchFn = (payload: AllActions) => void
export const useDispatch: () => DispatchFn = useReduxDispatch
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector
