import produce from 'immer'

import { AllActions } from './actions'


export type RectangleElement = {
  id: number
  name: string
  type: 'rectangle'
  x: number
  y: number
  width: number
  height: number
}

export type TextElement = {
  id: number
  name: string
  content: string
  type: 'text'
  x: number
  y: number
}

export type Element = RectangleElement | TextElement

export type ElementState = {
  elements: Element[]
}

const initialState: ElementState = {
  elements: []
}

export const elementReducer = (state: ElementState = initialState, action: AllActions) => {
  switch (action.type) {
    case 'DRAFT_ELEMENT_COMMIT': {
      return produce(state, draftStore => {
        const id = draftStore.elements.length
        const newElement: Element = {
          id,
          ...action.properties,
        }

        draftStore.elements[id] = newElement
      })
    }
    case 'ELEMENT_UPDATE_PROPERTY': {
      return produce(state, draftStore => {
        const elementToUpdate = draftStore.elements[action.id]
        // @ts-ignore
        elementToUpdate[action.property] = action.value
      })
    }
    default: {
      return state
    }
  }
}
