import produce from 'immer'

import { AllActions } from './actions'
import { useSelector, useDispatch } from '.'
import { useEffect, useRef } from 'react'

export type Mode = 'DEFAULT' | 'CREATE_RECTANGLE' | 'CREATE_TEXT'
export type Id = number | string

export interface AppState {
  mode: Mode
  hoveredElementId: Id | null
  selectedElementIds: number[]
}

const initialState: AppState = {
  mode: 'DEFAULT',
  hoveredElementId: null,
  selectedElementIds: [],
}

export const appReducer = (state: AppState = initialState, action: AllActions) => {
  switch (action.type) {
    case 'UPDATE_MODE': {
      return produce(state, draftState => {
        draftState.mode = action.mode
      })
    }
    case 'UPDATE_HOVERED_ELEMENT': {
      return produce(state, draftState => {
        draftState.hoveredElementId = action.id
      })
    }
    case 'UPDATE_SELECTED_ELEMENTS' : {
      return produce(state, draftState => {
        draftState.selectedElementIds = action.selectedElementIds
      })
    }
    default: {
      return state
    }
  }
}

export const useSelectedElements = () => {
  const selectedElementIds = useSelector(state => state.app.selectedElementIds)
  const dispatch = useDispatch()
  const shiftKeyIsPressedRef = useRef(false)

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      shiftKeyIsPressedRef.current = event.shiftKey
    }

    document.addEventListener('keydown', keydownListener)
    document.addEventListener('keyup', keydownListener)
    return () => {
      document.removeEventListener('keydown', keydownListener)
      document.removeEventListener('keyup', keydownListener)
    }
  }, [])

  const isSelected = (id: number) => {
    return selectedElementIds.includes(id)
  }

  const add = (id: number) => {
    dispatch({
      type: 'UPDATE_SELECTED_ELEMENTS',
      selectedElementIds: produce(selectedElementIds, draftState => {
        draftState.push(id)
      }),
    })
  }

  const replace = (id: number) => {
    dispatch({
      type: 'UPDATE_SELECTED_ELEMENTS',
      selectedElementIds: [id],
    })
  }

  const select = (id: number) => {
    if (shiftKeyIsPressedRef.current) {
      add(id)
    } else {
      replace(id)
    }
  }

  const remove = (id: number) => {
    dispatch({
      type: 'UPDATE_SELECTED_ELEMENTS',
      selectedElementIds: produce(selectedElementIds, draftState => {
        draftState = selectedElementIds.filter(elementId => elementId === id)
      }),
    })
  }

  const reset = () => {
    dispatch({
      type: 'UPDATE_SELECTED_ELEMENTS',
      selectedElementIds: [],
    })
  }

  return {
    ids: selectedElementIds,
    isSelected,
    add,
    remove,
    replace,
    select,
    reset,
  }
}