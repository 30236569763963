import React, { createContext, useRef, MutableRefObject, useContext, PointerEventHandler } from "react"
import styled from '@emotion/styled'
import { useSelectedElements, Mode } from "../../store/app"
import { useSelector } from "../../store"

import { useGesture } from "./useGesture"
import { Elements } from './Elements'
import { color } from "../../styles"

const PointerContext = createContext<MutableRefObject<{x: number, y: number}>>(null!)
const CanvasRefContext = createContext<MutableRefObject<HTMLDivElement>>(null!)

const cursorMapping: Record<Mode, string> = {
  CREATE_RECTANGLE: 'crosshair',
  CREATE_TEXT: 'text',
  DEFAULT: 'default'
}

const Container = styled.div<{ mode: Mode }>`
  flex: 1;
  position: relative;
  background-color: ${color.canvasBackground};
  overflow: hidden;
  touch-action: none;
  cursor: ${({ mode }) => cursorMapping[mode]};
`

const Board = styled.div`
  position: absolute;
`

export const usePointerPositionRef = () => {
  return useContext(PointerContext)
}

export const useCanvasRef = () => {
  return useContext(CanvasRefContext)
}

export const Canvas = () => {
  const canvasRef = useRef<HTMLDivElement>(null!)
  const boardRef = useRef<HTMLDivElement>(null!)
  const boardPositionRef = useRef({ x: 0, y: 0, zoom: 1 })
  const pointerPositionRef = useRef({ x: 0, y: 0 })
  const pointerRealPositionRef = useRef({ x: 0, y: 0 })

  const mode = useSelector(state => state.app.mode)
  const selectedElements = useSelectedElements()

  const updatePointerRealPosition = () => {
    const boardPosition = boardPositionRef.current
    const pointerPosition = pointerPositionRef.current

    pointerRealPositionRef.current = {
      x: (pointerPosition.x - boardPosition.x) / boardPosition.zoom,
      y: (pointerPosition.y - boardPosition.y) / boardPosition.zoom,
    }
  }

  useGesture(canvasRef, (position) => {
    boardPositionRef.current = position

    window.requestAnimationFrame(() => {
      const { x, y, zoom } = position
      const val = `translate3D(${x}px, ${y}px, 0px) scale(${zoom})`
      boardRef.current.style.transform = val
      updatePointerRealPosition()
    })
  })

  const handlePointerMove: PointerEventHandler = (event) => {
    const boundingClientRect = canvasRef.current.getBoundingClientRect()
    pointerPositionRef.current = {
      x: event.clientX - boundingClientRect.x,
      y: event.clientY - boundingClientRect.y,
    }

    updatePointerRealPosition()
  }

  return (
    <PointerContext.Provider value={pointerRealPositionRef}>
      <CanvasRefContext.Provider value={canvasRef}>
        <Container
          ref={canvasRef}
          mode={mode}
          onClick={() => selectedElements.reset()}
          onPointerMove={handlePointerMove}
        >
          <Board ref={boardRef}>
            <Elements />
          </Board>
        </Container>
      </CanvasRefContext.Provider>
    </PointerContext.Provider>
  )
}
