import React, { FC, SVGProps } from 'react'
import styled from '@emotion/styled'

import { shortcutMapping, ShortcutKey } from '../../useGlobalShortcut'
import { useSelector, useDispatch } from '../../store'
import { Rectangle, Text } from '../../components/Icon'
import { color } from '../../styles'
import { Stack } from '../../components/Stack'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;
  height: 40px;
  background-color: ${color.layoutBackground};
  border-bottom: 1px solid ${color.layoutBorder};
`

const AppTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${color.white};
`

const StyledShortcutButton = styled.button<{ isActive: boolean }>`
  padding: 8px;
  color: ${color.white};
  text-transform: uppercase;
  display: flex;

  ${({ isActive }) => isActive ? 'background-color: rgba(255, 255, 255, 0.08);' : ''}

  &:hover {
    background-color: rgba(255, 255, 255, 0.04);
    cursor: pointer;
  }
`

interface ShortcutButtonProps {
  shortcutKey: ShortcutKey
  icon: FC<SVGProps<SVGSVGElement>>
}

const ShortcutButton = ({ shortcutKey, icon: Icon }: ShortcutButtonProps) => {
  const mode = useSelector(state => state.app.mode)
  const dispatch = useDispatch()
  const buttonMode = shortcutMapping[shortcutKey]

  return (
    <StyledShortcutButton
      isActive={mode === buttonMode}
      onClick={() => dispatch({ type: 'UPDATE_MODE', mode: buttonMode })}
    >
      <Icon fill="white" width={24} height={24} />
    </StyledShortcutButton>
  )
}

export const Header = () => {
  return (
    <Container>
      <AppTitle>UI Editor Project</AppTitle>
      <Stack>
        <ShortcutButton shortcutKey="r" icon={Rectangle} />
        <ShortcutButton shortcutKey="t" icon={Text} />
      </Stack>
      <div />
    </Container>
  )
}