import styled from '@emotion/styled'

interface BaseElementProps {
  isHovered: boolean
  isSelected: boolean
}

export const BaseElement = styled.div<BaseElementProps>`
  position: absolute;
  ${({ isHovered, isSelected }) => {
    if (isSelected) {
      return 'box-shadow: 0 0 0 3px #2887d8;'
    }
    if (isHovered) {
      return 'box-shadow: 0 0 0 3px #81c0f7;'
    }

    return ''
  }}
`