import { useEffect } from 'react'

import { useDispatch } from './store'

export const shortcutMapping = {
  Escape: 'DEFAULT',
  r: 'CREATE_RECTANGLE',
  t: 'CREATE_TEXT',
} as const

export type ShortcutKey = keyof typeof shortcutMapping

const shortcutKey = Object.keys(shortcutMapping) as unknown as ShortcutKey

const isShortcutKey = (key: string): key is ShortcutKey => {
  return shortcutKey.includes(key)
}

export const useGlobalShortcut = () => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.metaKey === false && isShortcutKey(event.key)) {
        const shortcutName = shortcutMapping[event.key]
        dispatch({
          type: 'UPDATE_MODE',
          mode: shortcutName,
        })
      }
    }
    document.addEventListener('keydown', keydownListener)
    return () => document.removeEventListener('keydown', keydownListener)
  }, [dispatch])
}
