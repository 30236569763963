import React from 'react'
import styled from "@emotion/styled";

const StyledInput = styled.input`
  color: white;
  width: 100%;
  font-size: 12px;
  background-color: #3c3b3b;
  border-radius: 8px;
  height: 24px;
  padding: 0 8px;
`

interface InputProps {
  id?: string
  type?: string
  onChange?: (value: string) => void
  value?: string
}

export function Input({ value, onChange, ...otherProps }: InputProps) {
  return (
    <StyledInput
      {...otherProps}
      onChange={(evt) => {
        onChange?.(evt.target.value)
      }} 
      value={value}
    />
  )
}
